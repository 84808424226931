import React from 'react';
import PropTypes from 'prop-types';
import { Content, Line, MLabel, Title, Wrapper } from './styles';

const SalesSlipItem = ({ item }) => {

    return (
        <Wrapper to={`/sales-slip/${item.OriginalSalesSlipID}?slip_id=${item.SalesSlipID}`}>
            <Content>
                <Title >{item.ClientName}</Title>
                <Line>
                    <MLabel>現場：</MLabel>{item.SiteName || ''}
                </Line>
                <Line>
                    <MLabel>受注日：</MLabel>{item.AcceptedDate ? item.AcceptedDate.slice(0, 10) : '－'}
                    &emsp;&emsp;
                    <MLabel>納品日：</MLabel>{item.DeliveryDate ? item.DeliveryDate.slice(0, 10) : '－'}
                </Line>
                <Line>
                    <MLabel>商品：</MLabel>{item.GoodsFirst || '－'}
                    &emsp;&emsp;
                    <MLabel>数：</MLabel>{item.GoodsCount || '－'}
                </Line>

            </Content>
        </Wrapper>
    )
}

SalesSlipItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default SalesSlipItem;
