import axiosClient from "./axiosClient";

const assistantApi = {
    sendQuestion(params) {
        return axiosClient.post("/assistant", params);
    },
    sendMesssage(params) {
        return axiosClient.post("/assistant/pricing", params);
    },

}

export default assistantApi;
