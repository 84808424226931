import React from "react";
import { Body, Button, Header, Wrapper } from "./styles";
import ImgLogo from "../../assets/images/logo.png";


const Menu = () => {

    return <Wrapper>
        <Header>
            <img className="logo" src={ImgLogo} alt='Morien Logo' />
        </Header>
        <Body>
            <Button to={'/goods'}>新規注文</Button>
            <Button to={'/goods-stock'}>在庫発注</Button>
            <Button to={'/client'}>お客様一覧</Button>
            <Button to={'/client-management'}>お客様<br />（担当のみ）</Button>
            <Button to={'/sales-slip'}>伝票履歴</Button>
            <Button to={'/site'}>現場一覧</Button>
            <Button to={'/site-new'}>現場登録</Button>
            <Button to={'/action-list'}>訪問実績一覧</Button>
            <Button to={'/action'}>訪問実績登録</Button>
            <Button to={'/company'}>法人・個人</Button>
            <Button to={'/assistant'}>設計価格表</Button>
        </Body>
    </Wrapper>;
};

export default Menu;
